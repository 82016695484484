// Here you can add other styles

//Generic classes
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.mb-10{
  margin-bottom: 10px;
}

.mb-15{
  margin-bottom: 15px;
}

.ml-30{
  margin-left: 30px;
}

.force-mb-10{
  margin-bottom: 10px !important;
}

.mt-10{
  margin-top: 10px;
}

.force_mb-5{
  margin-bottom: 5px !important;
}

.force_mb-0{
  margin-bottom: 0 !important;
}

.mr-5-overwrite{
  margin-right: 5px;
}

.field_error{
  color: red; 
  font-size: 12px;
}

.field_warning{
  color: yellow;
}

.white_color{
  color: white;
}

.highcharts-credits{
  visibility: hidden !important;
}

.pointer:hover{
  cursor: pointer;
}

.css-jv2nzh-style{
  margin-top: 100px !important;
}

.fl-right{
  float:right;
}

.icon-size{
  font-size: 20px;
}

.icon-size:hover{
  color: black;
}

.icon_red{
  color: red
}

.icon_green{
  color: green
}

.icon_primary{
  color:#20a8d8
}

.ul_padding_overwrite{
  padding-left:0 
}

.ul_list_icon{
  padding-left:15px;
}

.ul_padding_overwrite li{
  list-style-type: none;
}

.overwrite_theader_padding{
  padding-top: 0;
  padding-bottom: 0;
}

.download_button_placement{
  position: absolute;
    top: 6px;
    right: 35px;
    z-index: 1;
}

.gray-color{
  color: gray;
}

.text-bold{
  font-weight: bold;
}

::placeholder, .rw-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c8cbd1 !important;
  // opacity: 1; /* Firefox */
}

// .rw-input[disabled]{
//   background-color: transparent !important;
// }

.form-control:disabled, .form-control[readonly] {
  background-color: transparent !important;
  opacity: 1;
}

.rw-dropdown-list-input{
  background-color: white !important;
}

.rw-input[disabled], fieldset[disabled] .rw-input{
  background-color: white !important;
}

.rw-widget-container {
  border: 1px solid #e4e7ea !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.rw-widget-input, .rw-filter-input{
  box-shadow: none !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #c8cbd1 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #c8cbd1 !important;
}

.clear-button{
  background-color: white !important;
  color: black !important;
}

.validation-error{
  border: 1px solid red !important;
  border-radius:4px; 
}

.button_loader{
  margin-right: 5px;
}

.bg_active{
  background-color: #4dbd74;
}

.bg_inactive{
  background-color: #f9cc43;
}

.bg_listed{
  background-color: #1b8eb7;
}




// ============================================================

//Loading SPinner

.loader{
  align-self: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width:100vw;
  z-index: 1;
  top: 0px;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;

}

.small_loader{
  align-self: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  position: absolute;
  height: 95%;
  width: 78%;
  padding-top: 35%;
}

.dashboard_card_loader{
  align-self: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  padding-top: 15%;
}


//Users list page


//User detail page
.user_img_parent{
  border: 1px solid #dedede; 
  padding: 5px; 
  border-radius: 4px;
  width:220px; 
  height:220px;
  margin: auto;
}

.userstable_profile_img{
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: 2px solid #dedede;
}

.user_img_parent .edit_icon, .user_img_parent .cancel_edit{
  visibility: hidden;
}

.user_img_parent:hover .edit_icon{
  visibility: visible;
}

.user_img_parent:hover .cancel_edit{
  visibility: visible;
}

.edit_icon{
  position: absolute;
  right: 30px;
  top: 10px;
  font-size: 25px;
  font-weight: 800;
}

.cancel_edit{
  position: absolute;
  right: 30px;
  top: 10px;
  font-size: 25px;
  font-weight: 800;
}

.edit_icon:hover, .cancel_edit:hover{
  cursor: pointer;
}

.dropdown .dropdown-menu-right{
  transform: translate3d(-85px, 45px, 0px) !important;
}

.image_input_tag{
  position: absolute;
  bottom: 15px;
  left: 30px;
}

.user_img_size{
  object-fit: contain;
}

//Ticket details page
.delete_note_button{
  height: 35px;
  margin-top: 20px;
  margin-left: 15px;
}

// =====================================================

//Admininstartion page
.add_admin_button{
  position: absolute;
  right: 35px;
  z-index: 1;
  top: 6px;
}

// =====================================================

//Dashboard page
.pending_info_card{
  min-height: 100px;
}

.pending-action .warningIcon {
  visibility: visible !important
}

.warningIcon {
  visibility: hidden;
}

.no-data{
  min-height: 250px
}

// =====================================================

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0,0,0,.02);
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}







